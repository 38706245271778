import React from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { withPage } from "../PageContainer";
import Selectors from "../Selectors";
import * as Widget from "../Components/Widget";
import _ from "lodash";

class RedirectPage extends React.Component {
  render() {
    let { profile } = this.props;
    const url = this._getRedirectUrl();

    return (
      <Wrapper>
        {(() => {
          if (!profile) {
            return (
              <Widget.Center>
                <h1 style={{ textAlign: "center" }}>
                  Verifying your identity, please wait...
                </h1>
                <h3 style={{ textAlign: "center", color: "grey" }}>
                  * You have to login first!
                </h3>
              </Widget.Center>
            );
          }

          if (url) {
            return (
              <Widget.Row justify="center" align="center">
                <Widget.Spinner />
                <h2 style={{ marginLeft: 10 }}>Redirecting to hospital...</h2>
              </Widget.Row>
            );
          } else {
            return <h2>{`The doctor or hospital is not set yet!`}</h2>;
          }
        })()}
      </Wrapper>
    );
  }

  componentDidMount() {
    this._tryRedirect();
  }

  componentDidUpdate() {
    this._tryRedirect();
  }

  _tryRedirect = () => {
    if (this.reidrecting) {
      return;
    }

    const url = this._getRedirectUrl();
    if (url) {
      this.redirecting = true;
      setTimeout(() => {
        if (window) {
          window.location = url;
        }
      }, 1500);
    }
  };

  _getRedirectUrl = () => {
    let { profile } = this.props;
    return _.get(profile, "hospital.url");
  };
}

const Wrapper = styled.div`
  min-height: calc(100vh - 60px);
  background-color: #eee;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export default withPage(
  connect(
    (state, ownProps) => ({
      profile: (() => {
        return Selectors.getLoginUser(state);
      })()
    }),
    null
  )(RedirectPage)
);
